@import 'components/layouts/SignDocuments.variables.less';

.collapse {
  :global(.ant-collapse-item) {
    background: @primary-011 !important;
    border-radius: 10px !important;
    :global(.ant-collapse-content-box) {
      padding-top: 3px !important;
      padding-left: 10px !important;
    }
    :global(.ant-collapse-header) {
      padding: 10px 13px 10px 13px !important;
      @media screen and (max-device-width: 500px) {
        padding: 6px 11px 6px 7px !important;
      }
      > :not(:global(.ant-collapse-arrow)) {
        margin-left: 20px;
      }
      :global(.ant-collapse-arrow) {
        padding-top: 8px !important;
        margin-left: -6px !important;
        @media screen and (max-device-width: 500px) {
          padding-top: 2px !important;
          margin-left: -8px !important;
        }
        svg {
          color: #5d5d5d !important;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 10px !important;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);