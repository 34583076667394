.priceText {
  text-transform: capitalize;
}
.modal {
  min-width: 50vw;
  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
  }
}
.benefitsContent {
  ul {
    padding-left: 20px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);