@import 'styles/variables.less';

.layout {
  min-height: 100vh !important;
  position: relative;
  background-color: var(--primary-color) !important;
}
.row {
  min-height: 100vh !important;
}
.card {
  display: flex;
  flex-flow: column;
  margin-top: 1rem !important;
  :global(.ant-card-body) {
    display: flex;
    flex-flow: column;
    min-height: 65vh;
    padding: 2.2rem 2rem;
  }
}

.logo {
  flex: 0 1 auto;
  margin-bottom: 1.2rem;
}
.content {
  flex: 1 1 auto;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);