.tabs {
  > :first-child {
    background-color: transparent;
    margin: 0 !important;
  }
  > :last-child {
    background-color: white;
    padding: 24px;
  }
  :global(.ant-tabs-tab) {
    background-color: white;
    border: none !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);