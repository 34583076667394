@import 'styles/variables.less';
.collapseCategoryProperties {
  margin-bottom: 16px;
}
.deleteCategoryButtonContainer {
  button.deleteCategoryButton {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0s;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    position: relative;
    button.deleteCategoryButton {
      position: absolute;
      right: 0px;
      z-index: 1;
    }
  }
}
.tabs {
  background-color: white;
  :global(.ant-tabs-nav-wrap) {
    background-color: #fafafa;
  }
  :global(.ant-tabs-content-holder) {
    padding: 0 16px 16px;
  }
  :global(.ant-tabs-tab) {
    border: none !important;
  }
}
.itemsContainer {
  background-color: white;
  padding: 16px;
}
.infoButton {
  margin-bottom: 20px;
}
.nameColumn {
  flex: 0 0 @col-6;
  width: @col-6;
}
.pricingFormulaColumn {
  flex: 0 0 @col-4;
  width: @col-4;
  word-break: break-all;
  // line-height: 1.2715;
}
.paymentIntervalColumn {
  flex: 0 0 @col-2;
  width: @col-2;
}
.activeColumn {
  min-width: 100px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);