.previewEmailTemplate {
  > :first-child {
    margin-bottom: 8px;
  }
}
.previewEmailTemplateCard {
  word-break: break-word;
  .previewEmailTemplateCardSubtitle {
    font-size: 16px;
  }
  /* .previewBody {
    > span > div {
      margin-bottom: 0;
    }
  } */
  .previewFooter {
    position: relative;
    margin-top: 48px;
    &::before {
      content: ' ';
      top: -24px;
      position: absolute;
      right: -24px;
      left: -24px;
      border-top: 1px solid #f0f0f0;
    }
  }
}
.attachmentsSwitchContainer {
  margin-bottom: 12px;
  .attachmentsSwitch {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    :global(.ant-form-item-explain-connected) {
      display: none;
    }
  }
}

.additionalAlert {
  margin-right: 2em;
}

.attachments {
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 16px;
  }
}
.okSendIcon {
  vertical-align: middle !important;
}
.recipientsInput {
  flex-direction: row !important;
  :global(.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background: transparent;
    color: black;
  }
  :global(.ant-select-disabled.ant-select-multiple .ant-select-selection-item) {
    color: black;
  }
  :global(.ant-form-item-control) {
    flex: 1;
    max-width: 100%;
  }
  > :first-child {
    padding: 0 !important;
    line-height: 32px !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);