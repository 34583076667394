.container {
  display: flex;
  width: 50%;
  height: 56px;
}
.expandIcon {
  display: flex;
  flex-direction: row;
}
.arrow {
  margin-left: 8px;
}
.drag {
  path {
    stroke: #999;
  }
}
.layer {
  width: 100%;
  padding: 48px;
  margin: -48px;
  @media (min-width: 480px) {
    max-width: 480px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1600px) {
    max-width: 1600px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);