.skeletonToolbar {
  display: flex !important;
  height: 54px !important;
  margin-bottom: 5px;
}
.skeletonInput {
  display: flex !important;
  height: 35px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);