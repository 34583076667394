@import 'components/layouts/SignDocuments.variables.less';

.input {
  background: #f6f6f6 !important;
  border-radius: 10px !important;
  font-size: 20px !important;
  padding: 12px 20px !important;
  border: none !important;
  @media screen and (max-device-width: 500px) {
    font-size: 14px !important;
    padding: 7px 15px !important;
    border-radius: 7px !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);