.action_button {
  border-radius: 4px !important;
  width: 26px !important;
  height: 26px !important;
  min-width: 26px !important;
  min-height: 26px !important;
  // border: 1px solid var(--primary-color) !important;
  // color: var(--primary-color) !important;
  margin: 4px !important;
}

.action_button:global(:not(.ant-btn-primary)) {
  color: rgba(0, 0, 0, 0.85) !important;
  border: 1px solid #d9d9d9 !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);