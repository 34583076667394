.contact_list {
  padding: 0px 20px;
}

.contact_list_list {
  max-height: 330px;
  overflow-y: auto;
  padding-right: 8px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);