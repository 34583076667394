.container {
  > :last-child {
    > :not(:last-child) {
      margin-right: 16px;
    }
  }
  > a,
  div {
    margin-bottom: 4px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);