.client_details_card_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: black;
}

.client_details_card_content > div {
  width: 33%;
}

.client_details_card_content > div > h3 {
  font-weight: 600;
  font-size: 15px;
}

.client_details_card_content > div:not(:last-child) {
  border-right: 1px solid #d9d9d9;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);