@import 'styles/variables.less';

.helperWidgetButton {
  color: @gray-7 !important;
}

.showPricesFormItem {
  align-items: center;
  label {
    height: auto !important;
    text-align: left;
  }
}

.shoppingCartCategoriesCard {
  > :global(.ant-card-body) {
    :global(.collapse-arrow) {
      margin-top: 4px !important;
      margin-bottom: -9px;
      display: block !important;
    }
    > :not(:last-child) {
      margin-bottom: 20px;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    b {
      font-weight: 600;
    }
  }
}
.groupOptions {
  display: flex;
  flex-direction: row;

  @media (max-width: 570px) {
    flex-direction: column;
  }
  > div {
    flex: 1;
  }
  > :first-child {
    margin-right: 16px;
  }
}
.switchesGroup {
  > div > div {
    flex-wrap: nowrap;
    align-items: center;

    margin-bottom: 0 !important;
    > :first-child {
      padding: 0 !important;
    }
    > :last-child {
      margin-right: 8px;
    }
  }
  > :last-child > div {
    margin-bottom: 24px !important;
  }
}
.optionNote {
  > :nth-child(2) {
    margin-top: 10px;
  }
}
:global(.user-role) {
  .goDashboard > div {
    display: none;
  }
}
.companyTypeNextButton {
  float: left;
  margin: 20px 0px 0px 0px;
}
.shoppingCartLogo {
  max-height: 100px;
  max-width: calc(100% - 50px);
}
.shoppingCartLogoLoading > div {
  height: 70px !important;
  width: 200px !important;
  max-width: 100%;
  span {
    border-radius: 20px;
    width: 100% !important;
    height: 100% !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);