.stepActions {
  display: flex;
  .actionButtonsGroupe {
    margin-left: auto;
    display: flex;
    button {
      margin: 0 5px;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);