.additionalButtonRow {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  button {
    margin-left: 16px;
  }
  button:first-child {
    margin-left: 0px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);