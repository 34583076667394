.actions {
  display: flex;
  flex-wrap: wrap;
}

.downloadButton {
  display: inline-flex !important;
  align-items: center;
  margin-right: 8px;
  > span > svg {
    vertical-align: -4px;
  }
}

.nameColumnItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  .signedBadge {
    margin-left: 12px;
    :global(.ant-badge-count) {
      background-color: var(--primary-color) !important;
      color: var(--btn-primary-color) !important;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);