.attachmentsSwitchContainer {
  margin-bottom: 16px;
  .attachmentsSwitch {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    :global(.ant-form-item-explain-connected) {
      display: none;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);