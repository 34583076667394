.collapsable {
  padding: 0px !important;
  :global(.ant-collapse-content-box) {
    padding: 0px !important;
    // background-color: red;
  }
  :global(.ant-collapse-header) {
    padding: 0px !important;
  }
}
.collapsablePanel {
  :global(.ant-collapse-content) {
    padding: 16px 24px;
    padding-bottom: 0px;
  }
}
.collapsablePanel_small {
  :global(.ant-collapse-content) {
    padding: 6px 24px !important;
    padding-bottom: 0px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);