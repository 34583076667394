@import 'styles/variables.less';

.placeholder {
  display: inline;
  margin-left: 6px;
  padding-top: 7px !important;
  &:hover {
    color: var(--primary-color);
    > :last-child {
      color: var(--primary-color) !important;
    }
  }
  > :nth-child(2) {
    margin-right: 15px;
  }
  > :last-child {
    transform: rotate(90deg);
    margin: -4px;
  }
}
.itemMenu {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.delimiter {
  height: 1px;
  background-color: #eee;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);