.ActionButtonGroupStacked {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;
}

.ActionButtonGroupStacked > * {
  margin-bottom: 8px;
}

.ActionButtonGroupStacked > *:last-child {
  margin-bottom: 0;
}

.ActionButtonGroupRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
}

.ActionButtonGroupRow > * {
  margin-right: 8px;
}

.ActionButtonGroupRow > *:last-child {
  margin-right: 0;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);