.Pagination {
  svg {
    vertical-align: baseline;
  }
}
.PaginationWrapper {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);