.draggable-decorator {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 5px;
  background-color: var(--primary-color);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);