.gray_text {
  font-size: 14px;
  color: #838383;
}

.button {
  color: #666 !important;
  font-size: 16px !important;
  padding: 2px !important;
}

.button_icon {
  font-size: 16px !important;
}

.email_button {
  margin-left: 8px !important;
}

.view_project_card_button {
  float: right;
}

.additional_info_text {
  color: rgba(0, 0, 0, 0.5) !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);