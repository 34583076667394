@import 'styles/variables.less';

.layout {
  min-height: 100vh !important;
  position: relative;
  background-image: url('../../assets/images/AuthBackground.png') !important;
  background-size: cover !important;
  .leftText > * {
    color: white;
  }
}
.logo {
  height: 50px;
  width: auto;
}
.card {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  > :first-child {
    padding: 40px;
    text-align: center;
  }
  > :last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    h2 {
      font-weight: bold;
      text-align: center;
    }
  }
}
.footer {
  background: transparent !important;
  padding: 0 !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);