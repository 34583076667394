// Dynamic ant design theme
@import '~antd/dist/antd.variable.less';

// Custom styles
@import 'container.less';
@import 'space.less';
@import 'common.less';
@import 'antd.less';
@import 'fonts.less';

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);