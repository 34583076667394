.card {
  background-color: transparent;
  padding: 0 !important;
  border: 1.4px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px !important;
  :global(.ant-card-body) {
    padding: 0px 22px;
  }
}
.header {
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  .iconWrapper {
    display: inline-block;
    margin-right: 12px;
    padding-top: 1px;
    color: rgba(0, 0, 0, 0.8);
  }
  .headingWrapper {
    display: inline-block;
  }
  h2 {
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px !important;
  }
}

.cardWrapper {
  :global(div.ant-card.ant-card-bordered) {
    border-radius: 7px !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);