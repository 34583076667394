@import 'components/layouts/SignDocuments.variables.less';

.button {
  background: white;
  border: none !important;
  color: @primary-100 !important;
  margin-left: auto;
  margin-top: auto;
  svg {
    font-size: 22px;
    @media screen and (max-device-width: 500px) {
      font-size: 20px;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);