.documentEntry {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  padding-bottom: 8px;
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
    display: inline;
  }
}
.documentEntry:last-child {
  border: none;
  margin-bottom: 0px;
}

.nameColumn {
  display: flex;
  align-items: center;
}

.iconContainer {
  margin-right: 8px;
  display: inline-block;
}

.icon {
  color: var(--primary-color);
  margin-top: 4px;
}

.button {
  padding: 2px 12px !important;
  min-height: 0px !important;
  font-size: 14px !important;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.documentTitleClickableWrapper {
  padding: 0;
  border: none;
  background-color: transparent;
}
.documentTitleClickableWrapper:focus {
  border: none;
  outline: none;
}
.documentTitleClickableWrapper:hover {
  cursor: pointer;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);