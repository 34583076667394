.card {
  background-color: transparent;
  padding: 0 !important;
  margin: 24px 0px !important;
  border: 1.4px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 7px !important;
  :global(.ant-card-body) {
    padding: 0px 6px;
  }
}
.cardWrapper {
  :global(div.ant-card.ant-card-bordered) {
    border-radius: 7px !important;
  }
}
.collapse {
  background-color: transparent !important;
  border: none !important;
  :global(.ant-collapse-content-box) {
    padding-top: 5px !important;
  }
}
.collapsePanel {
  border: none !important;
  background-color: transparent !important;
  :global(.ant-collapse-header) {
    background-color: transparent !important;
    border: none !important;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  :global(.ant-collapse-content) {
    background-color: transparent !important;
    border: none !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);