@import 'styles/variables.less';

.layout {
  min-height: 100vh !important;
  position: relative;
}
.content {
  display: flex;
}
.leftHeaderMenu {
  position: absolute;
  padding-left: 20px;
  display: flex;
  align-items: center;
  > div {
    margin: 0;
    padding: 0;
  }
  > :first-child {
    border-radius: 5px;
    margin: -5px 10px -10px -10px;
    padding: 10px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);