.pleaseAgree {
  color: rgba(0, 0, 0, 1);
  margin-bottom: 8px;
}

.checkbox :global(.ant-checkbox) :global(.ant-checkbox-inner) {
  border-color: var(--primary-color);
  width: 20px;
  height: 20px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);