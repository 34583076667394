.container {
  display: flex;
  flex-direction: column;
  > :first-child {
    width: fit-content;
  }
  .resetPassword {
    margin-top: 16px;
    flex: 1;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);