.ant-table-row:last-child > .ant-table-cell {
  border: none !important;
}
.ant-table-pagination.ant-pagination {
  margin-top: 0px !important;
}

div.ant-input-number {
  width: 100%;
}

div.ant-alert {
  margin-bottom: 10px !important;
}

.ant-tabs {
  overflow: visible; // => display shadow below edge buttons on press
  margin-bottom: 20px;
}

.hide-form-item-colon label:after {
  content: ' ';
  margin: 0 4px 0 2px;
}

.ant-alert-message {
  ul {
    margin-bottom: 0;
  }
}

.ant-modal.modal-top-50px {
  top: 50px !important;
}

.table-first-colum-min-width {
  thead tr:first-child th:first-child {
    width: 1px;
  }
}

.ant-form-item-label > label {
  white-space: break-spaces;
}
.ant-form-item-hide-error .ant-form-item-explain {
  display: none;
}
.ant-row.row-reverse {
  &.input-flex-unset > :nth-child(2) {
    flex: unset !important;
  }
  flex-direction: row !important;
  > :nth-child(2) {
    order: -1;
    max-width: fit-content;
    margin-right: 10px;
  }
  label {
    float: left;
  }
}
div.ant-row.ant-row-vertical > div {
  padding-bottom: 0 !important;
  display: flex;
}

// .ant-layout-sider-children {
//   .ant-menu-item-only-child {
//     padding: 0 calc(50% - 20px / 2) !important;
//   }
// }

.grey-link {
  color: rgba(0, 0, 0, 0.85);
  white-space: pre;
}

.primary-color-wave {
  border-top-color: var(
    --primary-color
  ) !important; // KJ-352, // node_modules/antd/es/_util/wave.js // button wave animation on click uses border-top-color as base color
}
@button-border-radius: 20px;
.ant-btn-group.ant-dropdown-button {
  > button:first-child,
  > :first-child button {
    border-top-left-radius: @button-border-radius !important;
    border-bottom-left-radius: @button-border-radius !important;
  }
  > button:last-child,
  > :last-child button {
    border-top-right-radius: @button-border-radius !important;
    border-bottom-right-radius: @button-border-radius !important;
  }
}
.ant-btn:not(.ant-btn-default):not(#button) {
  border-radius: @button-border-radius;
  min-height: 40px;
  &:not(.ant-btn-icon-only) {
    height: auto;
  }
  min-width: 40px;
  white-space: break-spaces;
  > span[role='img'] ~ span {
    // do not break icon
    display: inline;
  }
}
.ant-button-transparent,
.ant-button-transparent > .ant-btn {
  &:focus,
  &:hover {
    background: transparent !important;
  }
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
div.ant-card {
  padding-top: 10px;
  &:not(.margin-bottom-0) {
    margin-bottom: @card-margin-bottom;
  }
  &.ant-card-bordered {
    border-radius: 20px !important;
  }
  .ant-card-head-title {
    white-space: break-spaces;
    > :first-child.anticon {
      margin-right: 8px;
    }
  }
  .ant-card-actions {
    background: transparent;
    padding: 0 24px;
  }
}

button[ant-click-animating-without-extra-node='true']::after,
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  opacity: 0.8; // KJ-352
}

.shopping-cart {
  .ant-form-item-explain {
    display: none;
  }
  .ant-form-item-has-error {
    .ant-input-number,
    .ant-select-selector,
    .ant-picker,
    .ant-input-group-addon {
      border-color: var(--primary-color-alpha-50) !important;
    }
    .ant-input-group-addon {
      color: var(--primary-color-alpha-50) !important;
    }

    > .ant-form-item-control-input-content > :first-child {
      border-radius: 5px;
      box-shadow: 0 0 4px var(--primary-color-alpha-50) !important;
    }
  }
}

div.ant-form-item-has-error .ant-input-number-focused,
div.ant-form-item-has-error .ant-input-number-focused {
  box-shadow: 0 0 0 2px 2px var(--primary-color-alpha-50) !important; // KJ-706
}
div.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused
  .ant-select-selector {
  box-shadow: 0 0 0 2px 2px var(--primary-color-alpha-50) !important; // KJ-706
}
div.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--primary-color-alpha-50) !important; // KJ-706
}
tbody.ant-table-tbody td {
  white-space: break-spaces;
}
td.ant-table-cell-center-align {
  vertical-align: middle !important;
}
td.ant-table-cell {
  vertical-align: top; // KJ-823 Added top align cell-content of table-overview
}
.ant-message-custom-content {
  text-align: left;
  ul {
    margin: 16px 0;
  }
  .buttons {
    text-align: right;
  }
  span:last-child {
    float: right;
    margin-left: 4px;
  }
}
.drawer-footer-btn-float-left .ant-drawer-footer > div {
  float: left !important;
}

.ant-drawer-content-wrapper {
  height: calc(100% - var(--viewport-offset)) !important;
}

.ant-popover .ant-popover-inner {
  border-radius: 5px;
}
.ant-popover-arrow-hide {
  .ant-popover-arrow {
    display: none !important;
  }
}

.ant-label-overflow-unset .ant-form-item-label {
  overflow: unset !important;
}

.ant-table-container {
  overflow: overlay; // fix for category total table horizontal scroll on iphone
}
div.ant-picker-footer-extra {
  text-align: center;
  padding: 0;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none !important;
}

.ant-table-tbody > tr > td::before {
  content: '';
  transition: background 0.3s;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr:hover > td {
  background: #fafafa !important;
  &.ant-table-cell-fix-right,
  &.ant-table-cell-fix-left {
    &::before {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fafafa !important;
    }
    background: white !important;
  }
}

.ant-dropdown-menu-item .anticon {
  margin-right: 8px;
}

.ant-message-custom-content div.buttons {
  text-align: center;
}
.ant-btn-icon-only > svg {
  width: 100%;
}
thead.ant-table-thead > tr > th {
  overflow-wrap: unset;
  &,
  * {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  &.ant-table-cell-background-unset {
    background-color: #ffffff;
    border-bottom: #ffffff;
  }
}
.flex-align-right {
  display: flex;
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-header-remove-background thead.ant-table-thead > tr > th {
  background: unset;
}
.ant-table-wrapper.ant-table-remove-all-borders .ant-table-cell {
  border: unset;
}
.ant-table-row-divider > td:not(:last-child)::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 3px;
  height: 75%;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-row-delimiter {
  box-shadow: 0px 1px 0px 0px black;
  > td {
    padding: 1px !important;
  }
}
.ant-badge-background-primary-color .ant-badge-count {
  background: var(--primary-color);
}

//https://softwarepiloten.atlassian.net/browse/KJ-1340?focusedCommentId=13884
.ant-form-item-control-input {
  min-height: unset !important;
}

//https://softwarepiloten.atlassian.net/browse/KJ-1391
.ant-message-notice-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 35px);
}

.ant-collapse-without-horizontal-padding .ant-collapse-content-box {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-btn-round.ant-btn-sm {
  font-size: 14px !important;
}
