/* .deleteOption {
  margin-top: 5px;
  margin-bottom: 5px;
} */

.cancelOption {
  margin-top: 20px;
  margin-bottom: 10px;
}

.alertInfoBig {
  .ant-alert-icon {
    color: #1890ff;
  }
  > span {
    align-self: flex-start;
    font-size: 22px;
  }
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
}

.deleteOptionCard {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;

  .deleteOptionCardTitle {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .deleteOptionCardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .deleteOptionCardLeft {
      display: flex;
      align-items: center;
      flex: 1;

      .deleteOptionCardIcon {
        margin-right: 8px;
        font-size: 22px;
        align-self: flex-start;
      }

      .deleteOptionCardDescription {
        flex: 1;
      }
    }

    .deleteOptionCardButton {
      margin-left: 16px;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);