@import 'components/layouts/SignDocuments.variables.less';

.signButtonContainer {
  .signButton {
    width: 100%;
    height: 350px;
    background: #eee;
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: center;
    border-radius: 10px;
    @media screen and (max-device-width: 500px) {
      height: 170px;
      border-radius: 8px;
    }
    svg {
      font-size: 70px;
      color: @primary-100;
      @media screen and (max-device-width: 500px) {
        font-size: 40px;
      }
    }
    span {
      font-style: italic;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);