.confirmationContainer {
  width: 100% !important;
}
.confirmationStatusContainer {
  align-items: baseline !important;
}
.verifiedIcon {
  color: green !important;
  font-size: 18px !important;
}
.notVerifiedIcon {
  color: red !important;
  font-size: 18px !important;
}
.confirmationButton {
  color: rgba(0, 0, 0, 0.85) !important;
  margin-left: 8px !important;
  text-decoration-line: underline !important;
  font-weight: 500 !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);