.editButton {
  margin-right: 16px;
}
.helpButton {
  display: block !important;
}
.officialReasonText > span > div {
  display: inline;
}
.previewEmailCard {
  margin-top: 16px !important;
}
.previewCard {
  margin-top: 16px !important;
}
.signee {
  margin-top: 6px;
  margin-bottom: 0px !important;
}
.signeeEmail {
  font-size: 14px;
  color: #838383;
}
.generalProjectDataBody {
  margin-left: 16px;
}
.lockButton {
  padding-top: 8px !important;
  transform: translateX(10px);
}

.actionButtonContainer {
  padding-bottom: 10px;
}

.deleteButton {
  margin-left: 8px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);