@import 'styles/variables.less';

.contentArea {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.infoPageHeading {
  font-size: 2em;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .infoPageHeading {
    font-size: 1.8em;
    font-weight: bold;
  }
}

.headingWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    width: 80%;
    min-width: 280px;
    margin-bottom: 1.2rem;
  }
}

.alert {
  width: 80%;
  min-width: 280px;
  margin-top: 1.5em;
}

.form {
  align-self: stretch;
  display: flex;
  justify-content: center;
}

.password {
  width: 80% !important;
  min-width: 280px !important;
  margin-bottom: 1em !important;
}

.password_input {
  margin-top: 1.5em;
}

.buttonWrapper {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
}

.button {
  width: 80%;
  min-width: 280px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);