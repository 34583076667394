.modalHelper {
  > :first-child {
    > div {
      width: 100% !important;
      height: 300px;
      max-height: 100%;
      max-width: 100%;
    }
    margin: 0 !important;
  }
  > :last-child {
    margin-top: 16px;
  }
}
.button {
  display: inline;
  padding: 0 !important;
  color: #1890ff !important;
}

.imageContainer {
  display: flex;
  justify-content: center;
  .imageWrapper {
    width: unset !important;
    img {
      max-height: 300px;
      max-width: 100%;
      width: unset !important;
    }
  }
}
.videoWrapper {
  iframe {
    width: 100%;
    height: 100%;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);