div.switch {
  display: flex;
  flex-wrap: nowrap;
  .switchButton {
    margin-right: 10px;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
  }

  > :first-child {
    padding-bottom: 0 !important;
    display: flex;
    align-items: baseline;
  }
  button,
  label,
  span {
    cursor: pointer;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);