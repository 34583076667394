@import 'styles/variables.less';

.addContactButton {
  border: none;
}
.contact {
  background-color: @shopping-cart-background !important;
  margin-bottom: 0 !important;
}
.contactCard {
  display: flex;

  margin-bottom: 16px;
  .contactCardContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 16px;
  }
}
.contactCardActions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);