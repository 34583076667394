.warningAlert {
  width: 100% !important;
  min-width: 0 !important;
}
.question {
  margin-top: 24px;
  p {
    color: black;
    margin-bottom: 10px;
    font-weight: 500;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);