.layout {
  :global(.header-logo) {
    z-index: 1;
  }
  :global(.ant-layout-content) {
    background: transparent linear-gradient(180deg, #fd840a 0%, #fcad00 100%) 0% 0% no-repeat padding-box;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    padding-top: 0;
    position: absolute;
    top: 0;
    width: 100vw;
  }
  .helloContainer {
    align-items: center;
    height: 100%;
    place-content: center;
    color: white;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    > *:not(:first-child) {
      margin-top: 40px;
      @media screen and (max-device-width: 500px) {
        margin-top: 30px;
      }
    }
    @media screen and (max-device-width: 500px) {
      > :last-child {
        margin-top: auto;
        margin-bottom: -15px;
      }
      > :first-child {
        margin-top: auto;
      }
    }
    > svg {
      font-size: 70px;
      @media screen and (max-device-width: 500px) {
        font-size: 45px;
      }
    }
    > button {
      width: 320px;
    }
    @media screen and (max-device-width: 500px) {
      > button {
        width: 90vw !important;
      }
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);