.expandIcon {
  position: absolute;
  left: 20px;
  display: flex;
  flex-direction: row;
}
.arrow {
  margin-left: 8px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);