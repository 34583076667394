@import 'components/layouts/SignDocuments.variables.less';

.note {
  display: flex;
  flex-direction: column;
  background: @primary-011;
  padding: 18px;
  border-radius: 10px;
  line-height: 1.5;
  @media screen and (max-device-width: 500px) {
    padding: 15px;
    line-height: 1.3;
  }
  .title {
    margin-bottom: 10px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);