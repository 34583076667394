.alert {
  display: flex;
  > :first-child {
    margin-top: 2px;
    svg {
      fill: var(--primary-color);
    }
  }
  > :last-child {
    margin-left: 8px;
    flex: 1;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);