@import 'styles/variables.less';

.cardOption {
  height: 100%;
}

:global(.ant-card) .cardOptionActive {
  background-color: var(--primary-color);
  color: #fff;
  h2 {
    color: #fff;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);