.signed {
  color: #16b513 !important;
}
.icon {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  margin-right: 8px;
}
.collapseButtonPlaceholder {
  margin-right: 24px !important;
}
.collapsable {
  padding: 0px !important;
  :global(.ant-collapse-content-box) {
    padding: 0px !important;
    // background-color: red;
  }
  :global(.ant-collapse-header) {
    padding: 0px !important;
  }
}
.row {
  padding: 16px 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
  color: red;
  h3 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin: 0px;
    margin-right: 8px;
  }
  .state {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    align-self: flex-end;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);