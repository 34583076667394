.button {
  background: transparent linear-gradient(230deg, #fd840a 0%, #fcad00 100%) 0% 0% no-repeat padding-box !important;
  border: none !important;
  color: white !important;
  margin-left: auto;
  margin-top: auto;
  svg {
    font-size: 22px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);