.CollapsableButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CollapsableButtonIcon {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 10px;
}

.CollapsableContentWrapper {
  border-top: 1px solid #d9d9d9;
  margin-top: 10px;
  padding-top: 10px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);