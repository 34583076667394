.FixIcon {
  display: inline !important;
  font-size: 16px !important;
  line-height: 22px !important;
  vertical-align: baseline !important;
}
.FixIcon > svg {
  vertical-align: middle !important;
}

.True {
  color: green !important;
}

.False {
  color: red !important;
}

.icon_text_value_render {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .icon {
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);