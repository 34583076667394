.layout {
  min-height: calc(var(--vh, 1vh) * 100) !important;
  position: relative;
  background: white !important;
}

.content,
.header {
  margin-left: 40px;
  margin-right: 40px;
  @media screen and (max-device-width: 500px) {
    margin-left: 19px;
    margin-right: 19px;
  }
}
.content {
  display: flex;
  padding-top: 58px;
  padding-bottom: 30px;
  flex-direction: column;
  @media screen and (max-device-width: 500px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.header {
  margin-top: 27px;
  display: flex;
  @media screen and (max-device-width: 500px) {
    flex-direction: column;
    margin-top: 10px;
  }
  :global(.header-logo) {
    width: unset;
    padding: 0;
    height: unset;
    margin-bottom: auto;
    img {
      height: 37px;
      margin-left: 5px;
      margin-top: 5px;
    }
    @media screen and (max-device-width: 500px) {
      margin-left: 5px;
      img {
        height: 26px;
      }
    }
  }
  :global(.progress) {
    margin-left: auto;
    @media screen and (max-device-width: 500px) {
      margin-top: 30px;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);