.ListItem {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ListItemDataContainer {
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);