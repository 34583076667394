.ant-input-group {
  display: inline-table;
  &.addonAfterInput {
    .ant-input-number {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.ant-input-wrapper {
  height: fit-content;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);