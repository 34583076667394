.badge {
  margin-left: 12px !important;
  vertical-align: middle !important;
  top: -1px;
  :global(.ant-badge-count) {
    background-color: var(--primary-color) !important;
    color: var(--btn-primary-color) !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);