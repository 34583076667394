.infoBadge {
  background-color: var(--very-light-primary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 13px;
  border-radius: 2px;
  padding: 1px 5px;
  margin: 0px 4px;
  display: inline-block;
}

.tooltip {
  :global(.ant-tooltip-inner) {
    background-color: rgba(255, 255, 255, 0.85) !important;
    border: 1px solid var(--primary-color) !important;
    color: rgba(0, 0, 0, 0.85) !important;
    padding: 9px 9px;
  }
  :global(.ant-tooltip-arrow-content) {
    background-color: var(--primary-color) !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);