.formikImageContainer {
  width: 100%;
  // padding: 20px;
  .formikImage {
    // max-height: 200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: auto;
    margin-bottom: 10px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);