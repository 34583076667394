@actionButtonsYMargin: 20px;
@actionButtonsXMargin: 24px;
@actionButtonsHeight: 32px;

.stepsAction {
  position: absolute;
  bottom: -(@actionButtonsHeight + @actionButtonsYMargin);
  right: @actionButtonsXMargin;
}
:global(.ant-card).card {
  position: relative;
  min-height: 100%;
  padding-bottom: @actionButtonsHeight + @actionButtonsYMargin * 2;
  :global(.book-calendly-in-more-info) {
    display: none;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);