.listItemRowEntryLabel {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.listItemRowEntryValue {
  font-size: 14px;
  text-align: left;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);