.Row {
  width: 100%;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.BottomBorderedRow {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);