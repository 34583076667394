.enableDigitalSignatureSwitch {
  margin-bottom: 24px;
}
.indentSwitch {
  margin-top: 24px;
  margin-left: 50px;
}

.emailAddressesAlert {
  margin-top: 24px;
}

.sepaCustomSettings {
  margin-top: 16px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);