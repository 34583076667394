.container {
  position: relative;
  padding-top: 16px;
  div.title {
    position: absolute;
    margin-top: -12px;
  }
  .containerChild {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-top: 0;
    padding: 16px;
  }
}

:global(.success-color-bordered-box) .containerChild,
:global(.success-color-bordered-box div.ant-divider-horizontal.ant-divider-with-text::before),
:global(.success-color-bordered-box div.ant-divider-horizontal.ant-divider-with-text::after) {
  border-color: #b7eb8f;
}
:global(.success-color-bordered-box) {
  background-color: #f6ffed;
}

:global(.default-color-bordered-box) .containerChild,
:global(.default-color-bordered-box div.ant-divider-horizontal.ant-divider-with-text::before),
:global(.default-color-bordered-box div.ant-divider-horizontal.ant-divider-with-text::after) {
  border-color: #000;
}
:global(.default-color-bordered-box) {
  background-color: #999;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);