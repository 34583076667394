.divider {
  margin-top: 16px !important;
}
.introtext {
  font-size: 14px !important;
  margin-bottom: 10px !important;
  display: inline-block;
}
.domainNameField {
  height: 100% !important;
}
.domainAuthentificatedIcon {
  color: green !important;
}
.infoAlert {
  margin-bottom: 16px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);