.change_project_card_state_button {
  height: 20px !important;
  width: 20px !important;
  margin-left: 8px;
  top: -3px;
  :global(.anticon) {
    font-size: 14px !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);