@import 'components/layouts/SignDocuments.variables.less';
.doneContainer {
  .check {
    width: 75px;
    height: 75px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    background: white;
    @media screen and (max-device-width: 500px) {
      width: 62px;
      height: 62px;
      font-size: 28px;
    }
    svg {
      color: @primary-100 !important;
    }
  }
  @media screen and (max-device-width: 500px) {
    > :last-child {
      margin-bottom: auto !important;
      margin-top: 35px !important;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);