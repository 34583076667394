.labels {
  margin-right: 8px;
}
.placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  min-height: 25.144px;
}
.inputs {
  margin-right: 8px;
  min-width: 200px;
  color: rgba(0, 0, 0, 0.7);
  word-break: break-all;
}
.signeeManagement {
  padding: 16px 24px;
  padding-bottom: 0px;
  :global(.ant-col) {
    p:nth-child(n) {
      margin-bottom: 8px !important;
    }
    button:nth-child(n) {
      margin-bottom: 8px !important;
    }
  }
}
.buttonRow {
  margin-top: 4px !important;
  button {
    margin: 8px;
  }
  button:first-child {
    margin-left: 0px;
  }
}

.signeeInfoRow {
  margin-bottom: 16px;
  p {
    margin: 0px;
  }
}
.signeeInfoRowButtons {
  button {
    width: 100%;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);