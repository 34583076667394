@import 'styles/variables.less';

.customPriceButtons {
  display: flex;
  justify-content: flex-end;
  > :first-child {
    margin-right: 8px;
  }
}
.officialReasonText div {
  display: inline;
}
.editButton {
  padding: 0 !important;
  height: auto !important;
  color: @gray-7 !important;
  font-size: unset !important;
}
.removeButton {
  margin-bottom: 24px;
}
div.z {
  z-index: 50;
}
.infoText {
  cursor: pointer !important;
}
.inputFieldInfo {
  color: @gray-7;
  font-size: 0.8rem;
}
.allowedRange {
  display: flex;
  justify-content: space-between;
}
.defaultValue {
  display: flex;
  justify-content: flex-end;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);