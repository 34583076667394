@import 'styles/variables.less';

.collapseActive {
  > :first-child {
    background-color: var(--primary-color-alpha-50) !important;
  }
}

.collapse {
  border: none !important;
  background: #fff;
  > :first-child {
    border-radius: 5px !important;
    background-color: @shopping-cart-category-background;
    display: flex;
    > :nth-child(2) {
      flex: 1;
    }
    > :last-child {
      display: flex;
      align-items: center;
      margin: auto;
    }
  }
  > :last-child > div {
    padding: 16px 0 0 0 !important;
  }
}
.divider {
  margin: 16px 0;
}
.itemDivider {
  margin-top: 16px;
}
.inputFields {
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .categoryItem {
    @media (min-width: 992px) {
      min-width: calc((100% - 3 * 20px) / 2) !important;
      max-width: calc((100% - 3 * 20px) / 2) !important;
    }
  }
  > div {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: calc((100% - 1 * 20px) / 1);
    max-width: calc((100% - 1 * 20px) / 1);
    // @media (min-width: 768px) {
    //   min-width: calc((100% - 3 * 20px) / 3);
    //   max-width: calc((100% - 3 * 20px) / 3);
    // }
    @media (min-width: 992px) {
      min-width: calc((100% - 3 * 20px) / 3);
      max-width: calc((100% - 3 * 20px) / 3);
    }
    // @media (min-width: 1600px) {
    //   min-width: calc((100% - 6 * 20px) / 6);
    //   max-width: calc((100% - 6 * 20px) / 6);
    // }
    :first-child {
      padding-bottom: 0 !important;
    }
    label {
      word-break: break-all;
    }
    > div {
      margin-bottom: 0;
    }
  }
}

.discount label {
  font-weight: bold;
}

.categoryTotalWrapper {
  margin-top: 20px !important;
}
.itemText {
  word-break: break-all;
}
.scalesTable {
  margin-top: 8px;
}
.discountSelect {
  width: 400px !important;
  max-width: 100%;
}
.badgeContainer {
  position: relative !important;
  width: 20px;
  .badgeCategory {
    position: absolute !important;
    right: 0;
    top: -13px;
    sup {
      background-color: var(--primary-color);
    }
  }
}
.inputNumber {
  > :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  > :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.select,
.discountSelect {
  > :first-child {
    border-radius: 5px !important;
  }
}
.inputData {
  border-radius: 5px !important;
}
.categoryPanelHeader {
  display: inline-flex;
  flex-direction: column;

  > :first-child {
    margin: 0;

    font-weight: @shopping-cart-title-font-weight;
    font-size: @shopping-cart-title-font-size;
  }
}
.itemContainer {
  > div > div {
    margin: 0;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);