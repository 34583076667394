.checkboxInput {
  display: flex;
  > :first-child {
    top: -0.2em;
    padding: 0 !important;
    display: flex;
    align-items: baseline;
  }
}

.roundPrices {
  display: flex !important;
  flex-direction: column;
  > :not(:first-child) {
    margin-top: 8px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);