.modal {
  width: 720px !important;
}
.internalNote {
  .internalNoteTextArea {
    margin-top: 8px;
  }
  margin-bottom: 10px;
}
.heading {
  margin-top: 1em;
}
.attachmentsSwitchContainer {
  margin-bottom: 8px;
  .attachmentsSwitch {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    :global(.ant-form-item-explain-connected) {
      display: none;
    }
    ::after {
      display: none;
    }
    label {
      margin-top: 2px;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);