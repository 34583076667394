.wideModal {
  width: 80% !important;
  max-width: 1600px !important;
}

.mediumModal {
  width: 60% !important;
  max-width: 800px !important;
  min-width: 400px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);