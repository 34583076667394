.contact_card_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 15px;
}

.contact_card_name {
  font-weight: 600;
}

.contact_card_action_buttons {
  margin-left: auto;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);