.card {
  padding-top: 0px !important;
  :global(.ant-card-head) {
    border-color: #d9d9d9;
  }
  border-color: #d9d9d9 !important;
}

#cardv2.card {
  border-radius: 28px !important;
}

.card__small {
  :global(.ant-card-head) {
    padding: 0px 12px !important;
    min-height: 0px !important;
  }
  :global(.ant-card-head-title) {
    padding: 8px 0px !important;
  }
  :global(.ant-card-body) {
    padding: 12px !important;
  }
}

#cardv2.card__small {
  border-radius: 10px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);