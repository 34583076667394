@import 'styles/variables.less';

.subHeading {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 8px;

  font-size: @shopping-cart-title-font-size;

  .subHeadingText {
    flex: 1;
    white-space: normal;
    margin-block-end: 0;
  }
  &,
  * {
    font-weight: 500;
    font-size: 18px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);