.buttonWrapper {
  z-index: 1;
  cursor: grab;
}

.drag {
  path {
    stroke: #bfbfbf;
  }
}

.dragging {
  cursor: grabbing !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);