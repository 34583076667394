@import 'styles/variables.less';

@horizontalStepsToVertical: ~'(max-width: 1140px) and (min-width: 576px)';

.layout {
  min-height: 100vh !important;
  position: relative;
  background-color: white !important;
}
.headerRow {
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  padding: 10px;
}
.headerRowMobile {
  padding: 2px;
}
.contentRow {
  padding-top: 40px;
}
.stepsColumn {
  border-right: 2px solid rgba(0, 0, 0, 0.06);
  padding: 0px 0px 0px 32px;
  min-width: 276px !important;
}
.stepsColumnMobile {
  padding: 24px;
  padding-bottom: 0px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  width: 100%;
}
@media @horizontalStepsToVertical {
  .stepsColumnMobile {
    padding-bottom: 24px;
  }
}
.contentColumn {
  padding: 0px 56px 0px 32px;
}
.contentColumnMobile {
  padding: 0px 32px;
  margin-top: 10px;
}
.content {
  width: 100%;
}
.buttonBar {
  margin-top: 32px;
  margin-bottom: 32px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);