.keyValueBlock {
  max-width: 500px;
  :global(.ShortKeyValueBlockRow) {
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    b {
      font-weight: 400;
    }
  }
  :global(.KeyValueBlockRow) {
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    b {
      font-weight: 400;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);