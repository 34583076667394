.SortBarEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 32px;
  border: none;
  cursor: pointer;
  padding-left: 0px;
  background-color: transparent;
}

.ButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ButtonWrapperForSortButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 28.2812px !important;
}

.Button {
  font-size: 9px;
}

.UpButton,
.DownButton {
  display: inline;
  font-size: 9px !important;
  color: #bfbfbf !important;
  height: 9px !important;
  line-height: 9px !important;
}

.UpButton {
  vertical-align: bottom !important;
}

.DownButton {
  vertical-align: top !important;
}

.ActiveSort {
  color: var(--primary-color) !important;
}

.Label {
  font-size: 16px;
  line-height: 22px;
  margin-right: 8px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);