@import 'components/layouts/SignDocuments.variables.less';

.checkbox {
  font-style: italic;
  :global(.ant-checkbox) {
    top: 0.1em;
    transform: scale(1.9);
    transform-origin: left;
    box-shadow: 0px 3px 6px #fd840a65;
    @media screen and (max-device-width: 500px) {
      transform: scale(1.3);
      top: 0.2em;
    }
    + span {
      padding-left: 25px;
      @media screen and (max-device-width: 500px) {
        padding-left: 15px;
      }
    }
    &:global(.ant-checkbox-checked) {
      &:after {
        border: 2px solid @primary-100 !important;
      }
      :global(.ant-checkbox-inner) {
        background-color: @primary-100 !important;
        &:after {
          left: 15%;
        }
      }
    }
    :global(.ant-checkbox-inner) {
      // background-color: @primary-100 !important;

      border: 2px solid @primary-100 !important;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);