.additionalFilters {
  :global(.ant-collapse-header) {
    padding-left: 0px !important;
  }
  :global(.ant-collapse-content-box) {
    padding-top: 0px !important;
    padding-left: 24px !important;
    padding-right: 24px impr !important;
  }
  .digitalSignatureStateFilterLabel {
    display: block;
    margin-bottom: 8px;
  }
}

.digitalSignatureStateColumnItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .digitalSignatureStateIcon {
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);