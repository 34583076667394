.ant-modal-confirm-btns {
  margin-top: -4px;
  button {
    margin-top: 4px;
    margin-left: 8px;
    float: right;
  }
}

.unsaved-changes-modal .ant-modal-confirm-btns {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  button {
    margin-left: 0px !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);