@import 'components/layouts/SignDocuments.variables.less';

.document {
  align-items: center;
  background-color: @primary-011;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 180px;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  width: 180px;
  @media screen and (max-device-width: 500px) {
    border-radius: 8px;
    height: 105px;
    width: 105px;
  }
  .fileContainer {
    display: flex;
    flex: 3;
    margin-top: 10px;
    .file {
      color: @primary-100;
      font-size: 55px;
      margin: auto;
      @media screen and (max-device-width: 500px) {
        font-size: 33px;
      }
    }
  }
  .nameContainer {
    flex: 2;
  }
  .menu {
    position: absolute;
    right: 10px;
    top: 14px;
    color: @primary-100;
    @media screen and (max-device-width: 500px) {
      top: 8px;
      right: 5px;
      font-size: 13px;
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);