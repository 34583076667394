.stateColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.stateColumnText {
  margin: 0px;
  margin-left: 8px;
}
.stateRow {
  padding: 16px 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);