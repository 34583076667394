.officialReasonText > span > div {
  display: inline;
}
.category:not(:last-child) {
  margin-bottom: 24px;
  border-bottom: solid 1px #e0e0e0;
}

.categoryBody {
  margin: 0 16px;
  padding-top: 10px;
}

.category {
  padding-bottom: 40px;

  h2 {
    margin-bottom: 12px;
  }
}

.categoryInfoBlock {
  margin-bottom: 10px;
}

.shoppingCartStaticItems {
  margin-bottom: 16px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);