@import 'styles/variables.less';

@padding-items-collapse-menu: 12px 16px;

.headerContainer {
  background-color: white !important;
  padding: 0px !important;
}
.header {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: normal;
  text-align: left;
  height: 100%;
  display: flex;
  align-items: center;
}
.logoWrapper {
  overflow: hidden;
  height: 64px;

  display: flex;
  align-items: center;

  line-height: 40px;

  padding: 0 16px 0 24px;
  > :last-child {
    margin: 0;
  }
}
.logo {
  cursor: pointer;
  position: relative;
  user-select: none;
  height: 30px;
  width: auto;
  margin-right: 20px;
}
.choppingCardLogo {
  padding: 0;
  > img {
    height: 34px;
  }
}
.headerRight {
  display: flex;
  align-items: center;
  margin-left: auto;
  button > svg {
    margin: auto;
    display: flex;
  }
  & > :not(:last-child) {
    margin-right: 20px;
  }
  > button {
    display: flex;
    align-items: center;
  }
}
.collapseMenu {
  > div:not(:last-child) {
    margin: 8px 0;
    border-width: 2px;
  }
  > :last-child,
  > a > :first-child {
    margin-top: 10px;
    padding: 2.4px 10px 2.4px 0;
    > :first-child {
      margin-right: 8px;
    }
    &:hover {
      cursor: pointer;
      background-color: var(--hover-primary-color);
      padding-left: 10px;
      padding-right: 20px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  > :last-child {
    color: var(--primary-color);
  }
}
.avatarInfo {
  > * {
    margin-top: 10px;
    display: block;
  }
  > :first-child {
    text-align: center;
    margin-top: -10px;
  }
}
:global(.cursor-pointer.sb-avatar__text) {
  background-color: var(--primary-color) !important;
}
.desaturate {
  filter: grayscale(100%);
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);