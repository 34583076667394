@import 'components/layouts/SignDocuments.variables.less';

.itemInCollapse {
  display: flex;
  margin-left: 2px;
  .iconColumn {
    width: 25px;
    font-size: 15px;
    @media screen and (max-device-width: 500px) {
      font-size: 13px;
    }
    display: flex;
    svg {
      color: @primary-100;
      margin-top: 7px;
      @media screen and (max-device-width: 500px) {
        margin-top: 4px;
      }
    }
  }
  .itemColumn {
    display: flex;
    flex-direction: column;
    .titleRow {
      display: flex;
      svg {
        place-self: center;
        margin-left: 7px;
        color: #c7c7c7;
        font-size: 18px;
        margin-top: 2px;
        @media screen and (max-device-width: 500px) {
          font-size: 13px;
          margin-top: 0;
        }
      }
    }
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);