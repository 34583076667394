@import '../../styles/variables.less';

.fullscreen {
  position: absolute;
  left: 0;
  top: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: @z-index-loading-overlay;
  width: 100%;
  height: 100%;
}

.fixed {
  position: fixed;
  top: 0;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);