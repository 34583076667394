.copyCLLinkButton {
  margin-top: 0 !important;
  padding-left: 0;
}

.modalTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 0px !important;
  text-wrap: wrap !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);