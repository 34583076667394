.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Text {
  margin-left: 5px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);