.formItem {
  :global(.ant-form-item-explain) {
    display: none;
  }
  margin-bottom: 12px !important;
}

.textAreaCopy {
  padding-right: 30px !important;
  &:hover {
    cursor: pointer;
  }
}

.textAreaWrapper {
  display: flex;
  flex-direction: row;
}

.copyIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: rgba(0, 0, 0, 0.25) !important;
}

.copyIconOnHover {
  color: rgba(0, 0, 0, 0.5) !important;
}
.copyIcon:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}
.copyIconClicked {
  color: rgba(0, 0, 0, 0.2) !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);