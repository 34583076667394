@import 'styles/variables.less';
@import 'styles/columns.less';

#root {
  max-height: 100vh;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  --primary-color: @primary-color;
}

#content-holder {
  flex-grow: 1;
  overflow: auto;
}

.display-none {
  display: none;
}
.display-block {
  display: block !important;
}
.display-inline-block {
  display: inline-block;
}
.multiline {
  white-space: pre-line;
}
div,
h2 {
  &.margin-bottom-0 {
    margin-bottom: 0;
  }
}

.white-background {
  background: white;
}

.padding-16 {
  padding: 16px;
}

.margin-16 {
  margin: 16px;
}
.margin-0 {
  margin: 0;
}
.margin-left-8 {
  margin-left: 8px !important;
}
.margin-right-16 {
  margin-right: 16px;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-top-0 {
  margin-top: 0;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.pointer-not-last-cell .ant-table-cell:not(:last-child) {
  cursor: pointer;
}

.input-confirm-modal .ant-form-item > .ant-col-18 {
  max-width: 100% !important;
  flex: 1 1 !important;
}
.flex-1 {
  flex: 1;
}
.flex-3 {
  flex: 3;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.sub-title {
  color: rgb(79, 79, 79);
  font-style: italic;
}
.align-center,
.center {
  display: flex;
  align-items: center;
  min-height: 32px; // button height
}
.center {
  justify-content: center;
}
.white-space-nowrap {
  white-space: nowrap;
}

.table-text {
  padding-top: 20px !important;
}

.box-shadow-gray {
  box-shadow: 0 0 1px rgb(172, 172, 172);
}

.hover-background-light-primary:hover {
  background: var(--hover-primary-color) !important;
}

.border-gray {
  border: 1px solid #d9d9d9;
}
.otp-input {
  @media (min-width: 768px) {
    display: flex;
    > :first-child {
      max-width: 150px;
      min-width: 100px;
      margin-right: 16px;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.remove-right-border {
  border-right: none !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
div.alert-info {
  .ant-alert-icon {
    color: #1890ff;
  }
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
}

#root {
  max-height: 100vh;
  height: 100%;
  overflow: auto;
}

body.no-scroll * {
  touch-action: none;
  [draggable='true'] {
    touch-action: manipulation;
  }
}
.card-borderless {
  border: none !important;
  .ant-card-head {
    border: none !important;
  }
}
.ant-card-head-borderless {
  .ant-card-head {
    border: none !important;
  }
}
.table-cell-break-all {
  word-break: break-all;
}
.button-border-none {
  border: none;
}
.button-box-shadow-none {
  box-shadow: none;
}
.height-100-percent {
  height: 100%;
}
iframe.hiddenAbsolute {
  visibility: hidden;
  position: absolute;
  height: 0;
  border: 0;
}
.margin-bottom-0-item-form > div > div {
  margin-bottom: 0;
}
.options {
  padding: 10px;
  margin: -10px;
  user-select: none;
  border-radius: 5px;
  font-size: 20px;
}
.shopping-cart-table .ant-card-head {
  border: none !important;
}
.shopping-cart-table {
  .ant-table-container {
    width: calc(100% + 32px);
  }
  table {
    margin: 0 -16px;
    border-spacing: 16px 1px !important;
    thead {
      th {
        background-color: @shopping-cart-background;
        border-radius: 5px;
      }
    }
    td {
      border: none !important;
    }
    .ant-table-row-delimiter {
      box-shadow: 0px 1px 0px 0px #d0d0d0;
    }
  }
  i {
    color: @gray-7;
  }
}
.max-width-100 {
  max-width: 100%;
}
.full-width {
  width: 100%;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.red-warning-text {
  font-weight: bold;
  color: red !important;
}
.rich-editor-read-only {
  > div {
    padding: 0;
    border: none;
    background-color: unset;
  }
}
.rich-editor-no-paragraphs {
  > div > div > div {
    margin-bottom: 0 !important;
  }
}
.collapse-arrow svg {
  color: @collapse-arrow;
  transition: transform 0.24s;
}
.collapse-arrow-active svg {
  transform: rotate(90deg);
}

.pre {
  white-space: pre;
}
div.form-item-no-margin {
  .ant-form-item-label {
    align-items: unset;
  }
  .ant-form-item-explain {
    min-height: unset;
  }

  margin: 0 !important;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.textTransformFirstLetterCapitalize::first-letter {
  text-transform: capitalize;
}
body > .row-dragging::before {
  content: ' ';
  position: absolute;
  background-color: var(--primary-color);
  width: 5px;
  top: 0;
  bottom: 0;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  vertical-align: middle;
  z-index: 1002;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.action-column-2 {
  min-width: 103px;
}
.table-wrapper {
  border: 1px solid #f0f0f0;
}

.display-table-cell {
  display: table-cell !important;
}

.margin-auto {
  margin: auto;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-decoration-style-dotted {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-underline-offset: 4px;
}
.info-text,
.autosave-changes-button {
  color: @gray-7;
  font-style: italic;
}
.overflowWrapAnywhere {
  overflow-wrap: anywhere !important;
}
table ul li {
  display: table cell !important;
}
.subtitle {
  font-size: 0.9em;
}
.font-size-1-5 {
  font-size: 1.5em;
}
.auth-input {
  border-radius: 25px !important;
  padding: 11px 20px !important;
}
.site-form-item-icon {
  margin-right: 8px;
}
.float-right {
  float: right;
}

.hide-mobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}
.hide-desktop {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f2f5;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
