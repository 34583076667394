@import 'styles/variables.less';

.infoIcon {
  color: var(--primary-color) !important;
  font-size: 20px !important;
}
.checkIcon {
  color: @shopping-cart-inactive !important;
}
.activeItemCheckbox * {
  fill: var(--primary-color);
}

.itemHeader {
  min-height: 40px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  border-radius: 5px;

  background-color: @shopping-cart-item-background !important;
  > :first-child {
    > :first-child {
      display: flex;
      align-items: center;
    }
    display: flex;
    flex-direction: column;
  }
}

.infoTextModalCollapse {
  > :first-child {
    margin-left: -16px;
    > :last-child > div > :nth-child(even) {
      margin-bottom: 16px;
    }
  }
}
button.itemButton {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px !important;
  border: none;
  box-shadow: none;
}
.itemCart {
  > div > div > div > {
    display: flex;
    > :not(:last-child) {
      margin-right: 8px;
    }
    > button {
      background-color: @shopping-cart-background !important;
    }
  }
  .itemMainInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    > :first-child {
      margin-bottom: 8px;
    }
  }
  > * {
    margin-top: 8px;
  }
  .item {
    display: flex;
    @media (max-width: 500px) {
      flex-direction: column;
    }
    .itemFieldContainer {
      flex: 2;
      :global(.sub-title) {
        margin-left: 24px;
      }
    }
    .itemPricePreview {
      flex: 1;
      margin-bottom: auto;
      color: @gray-7;
    }
  }
  .title {
    margin: 0;

    font-weight: @shopping-cart-title-font-weight;
    font-size: @shopping-cart-title-font-size;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);