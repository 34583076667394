@import './colors.less';

.col(@width) {
  flex: 0 0 @width;
  width: @width;
}
.col-1 {
  .col(@col-1);
}
.col-2 {
  .col(@col-2);
}
.col-3 {
  .col(@col-3);
}
.col-4 {
  .col(@col-4);
}
.col-5 {
  .col(@col-5);
}
.col-6 {
  .col(@col-6);
}
.col-7 {
  .col(@col-7);
}
.col-8 {
  .col(@col-8);
}
.col-9 {
  .col(@col-9);
}
.col-10 {
  .col(@col-10);
}
.col-11 {
  .col(@col-11);
}
.col-12 {
  .col(@col-12);
}
