.button {
  width: 100%;
  background-color: var(--very-light-primary-color) !important;
  border: none !important;
  border-radius: 7px !important;
  padding: 40px 20px !important;
  margin: 0px;
  margin-bottom: 8px;
  min-height: 142px !important;
}

.signedButton {
  padding: 0px 20px !important;
  background-color: white !important;
  border: 1.4px solid rgba(0, 0, 0, 0.25) !important;
}

.spinContainer {
  margin-bottom: 4px;
}

.buttonText {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}

.errorMessage {
  color: rgb(255, 77, 79);
}

.notSignedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);