.enableDigitalSignatureSwitch {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  :global(.ant-form-item-explain-connected) {
    display: none;
  }
  ::after {
    display: none;
  }
  label {
    margin-top: 2px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);