.group {
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    & > :not(:last-child) {
      margin-right: 20px;
    }
  }
  > div {
    flex: 1;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);