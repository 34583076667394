@import 'styles/variables.less';

.questionTextLabel {
  border-radius: 5px;
  background-color: var(--primary-color-alpha-50);
  font-weight: 600;
  padding: 8px;
  padding-left: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);