@import 'styles/variables.less';

.modal {
  position: relative;
  width: calc(90vw - 48px);
  height: calc(100vh - 50px - 24px - 24px - 53px - 24px - 20px - 10px);
  padding: 24px 0;
}

.documentPreviewInfoWidget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.infoWidgetButton {
  color: @gray-7 !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);