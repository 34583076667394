.button-container {
  display: inline-block;
  position: relative;

  .above-button {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: not-allowed;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);