.table {
  margin-bottom: 16px;
  .tableCell {
    padding: 4px 1px;
    // padding-bottom: 4px;
    border: none;
    background-color: transparent;
    font-size: 15px;
  }
  .tableCell:nth-last-child(n + 2) {
    margin-right: 12px;
  }
  .headerCell {
    display: inline-block;
    padding: 2px;
    border-bottom: 1.6px solid rgba(0, 0, 0, 0.25);
    font-weight: 500;
  }
  .paymentIntervalCell {
    text-transform: capitalize;
  }
  .paymentIntervalColumn {
    min-width: 100px;
    width: 8%;
    word-wrap: break-word;
    align-self: flex-start;
  }
  .priceCell {
    min-width: 120px;
    width: 5%;
    b {
      font-weight: 600;
    }
    i {
      color: rgba(0, 0, 0, 0.7);
      font-style: italic;
      font-size: 14px;
    }
  }
  .priceInfoCell {
    color: rgba(0, 0, 0, 0.7);
    font-style: italic;
    font-size: 14px;
    b {
      font-weight: 600;
    }
  }
  .onActualCostInfo {
    font-weight: 500;
  }
  .vatText {
    margin-top: 12px;
  }
  .separatedRow {
    margin-top: 16px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);