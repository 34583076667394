.type {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
.allowedRange {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
}
.defaultType {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);