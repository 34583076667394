.Background {
  background-color: rgba(0, 0, 0, 0.65);
  height: 100vh;
  width: 100vw;
  z-index: 1100;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ScrollPane {
  width: 100vw;
  flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: scroll;
  text-align: center;
}
.Document {
  margin: auto;
  height: 100%;
  aspect-ratio: 210/297;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :global(.Document_OnLoadingComponent) {
    background-color: transparent !important;
    color: #f0f0f0;
    :global(.ant-spin-dot) {
      color: var(--hover-primary-color) !important;
    }
  }
}
.DocumentWidthControlled {
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :global(.Document_OnLoadingComponent) {
    background-color: transparent !important;
    color: #f0f0f0;
    :global(.ant-spin-dot) {
      color: var(--hover-primary-color) !important;
    }
  }
}
.StickyBar {
  top: 56px !important;
}
.List {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.List::-webkit-scrollbar {
  display: none;
}
:global(.ant-tooltip) {
  z-index: 1200 !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);