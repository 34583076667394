.PdfDrawer {
  :global(.ant-drawer-content-wrapper) {
    width: calc(100% - 300px) !important;
    min-width: 1200px !important;
    height: 100%;
  }
  :global(.ant-drawer-content) {
    width: 100% !important;
    height: 100%;
  }
  :global(.ant-drawer-wrapper-body) {
    width: 100% !important;
    height: 100%;
    max-width: 100vw !important;
  }
  :global(.ant-drawer-body) {
    width: 100% !important;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.PdfDrawerWidthControlled {
  :global(.ant-drawer-content-wrapper) {
    width: 100% !important;
    height: 100%;
  }
  :global(.ant-drawer-content) {
    width: 100% !important;
    height: 100%;
  }
  :global(.ant-drawer-wrapper-body) {
    width: 100% !important;
    height: 100%;
  }
  :global(.ant-drawer-body) {
    width: 100% !important;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);