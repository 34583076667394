.Page {
  margin: 0;
  :global(.annotationLayer) {
    width: 100%;
  }
  canvas {
    border-bottom: 1.3px dotted rgba(0, 0, 0, 0.25);
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);