.message {
  p {
    margin-bottom: 6px;
  }
  p:last-child {
    margin-bottom: 0px;
  }
  :global(.anticon) {
    margin-top: 3px;
  }
}
.message > div {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
.closeButton {
  color: rgb(114, 114, 114) !important;
  background-color: white;
  border-radius: 99px;
  position: absolute !important;
  top: 3px !important;
  right: -10px !important;
  z-index: 99999;
  font-size: 14px !important;
}
.contentContainer {
  position: relative;
  padding-right: 28px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);