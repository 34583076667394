.SendedEmailCard {
  word-break: break-word;
  .Subtitle {
    font-size: 16px;
  }
  .Message {
    > span > div {
      margin-bottom: 0;
    }
  }
  .AttachmentsWrapper {
    position: relative;
    margin-top: 24px;
    &::before {
      content: ' ';
      position: absolute;
      right: -24px;
      left: -24px;
      border-top: 1px solid #f0f0f0;
    }
    .Attachments {
      padding-top: 20px;
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);