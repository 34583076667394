.container {
  cursor: move;
  margin: -10px -15px;
  padding: 10px 15px;
  z-index: 1;
}
.drag {
  path {
    stroke: #999;
  }
}
.borderDrag {
  position: relative;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);