.holder {
  margin-left: 55px;
  margin-bottom: 25px;
  @media screen and (max-width: 1280px) {
    margin-left: 0;
  }
}

.radioGroup {
  margin-bottom: 25px !important;
}

.styledTransfer {
  display: flex;
  width: 100%;
  @media (max-width: 1280px) {
    flex-direction: column;
  }

  :global(.ant-transfer-operation) {
    @media (max-width: 1280px) {
      transform: rotate(90deg);
    }
  }

  :global(.ant-transfer-list) {
    flex: 1;
    min-height: 400px;
    max-height: 400px;
    @media (max-width: 1280px) {
      width: auto;
      max-height: 300px;
      min-height: 300px;
    }
  }

  :global(.ant-transfer-list-content-item-text) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);