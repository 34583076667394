.bankaccount_card_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 15px;
}

.bankaccount_card_data {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.bankaccount_card_data_label_column {
  font-weight: 600;
}

.bankaccount_card_sepa_link {
  text-decoration: underline;
  color: #3595fe;
  margin-left: 6px;
}

.bankaccount_card_action_buttons {
  margin-left: auto;
}

.bankaccount_card_sepa_not_signed_info {
  margin-left: 6px;
  font-size: 15px;
}

.bankaccount_details {
  flex: auto 1 1;
}

.bankaccount_details_label {
  width: 100px;
  font-weight: 600;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);