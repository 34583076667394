.introText {
  p {
    margin-bottom: 0.2em;
  }
  .contactUsLine {
    margin-top: 0.2em;
  }
}

.stepWrapper {
  margin-top: 32px;
}

// there is a hidden dom element
// ant-form-item-explain ant-form-item-explain-connected
// that creates a larger white space
// in normal antd this is not present 🤔
.formItemFix {
  margin-bottom: -24px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);