.radio {
  .radioWithLabel {
    margin-right: 0px;
  }

  .tooltipIcon {
    font-size: 16px;
    position: relative;
    top: 0.3em;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);