.PdfWrapper {
  display: inline-block;
  height: 100%;
  margin-right: 0 !important;
  overflow: scroll;
  position: relative;
  border: 1.6px solid rgba(0, 0, 0, 0.25) !important;
  background-color: gray;
}

.PdfWrapperHeightControlled {
  min-width: 400px;
}

.PdfWrapperWidthControlled {
  width: 100%;
}

.PdfWrapper_PinchZoomPane {
  height: 100%;
}

.PdfWrapper_PinchZoomPaneWidthControlled {
  width: 100%;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);