.typeText {
  display: inline-block !important;
  margin-bottom: 4px !important;
}

.entryTypeInput {
  width: 250px !important;
  margin-right: 8px !important;
}

.hostnameInput {
  flex-grow: 1;
}

.checkCircleConnected {
  color: green !important;
  vertical-align: -0.225em !important;
}

.checkCircleNotConnected {
  color: red !important;
  vertical-align: -0.225em !important;
}

.firstRow {
  & :nth-child(2) {
    flex-grow: 1;
  }
}

.h4Subheading {
  font-size: 16px !important;
  margin-bottom: 10px !important;
  margin-right: 4px !important;
  display: inline-block !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);