.button {
  border-radius: 100px !important;
  width: 400px;
  height: 47px !important;
  @media screen and (max-device-width: 500px) {
    width: 100% !important;
    height: 40px !important;
  }
}

.rightIcon {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);