@import 'styles/variables.less';

.contentArea {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.infoPageHeading {
  font-size: 2em;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .infoPageHeading {
    font-size: 1.8em;
    font-weight: bold;
  }
}

.headingWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    width: 80%;
    min-width: 280px;
  }
}
.additionalIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
  .additionalIcon {
    color: var(--primary-color);
    font-size: 64px;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);