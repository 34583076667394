.OnLoadingComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
}
.spin {
  margin-bottom: 32px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);