.SortBar {
  margin-left: 16px;
  margin-bottom: 16px;
  > button:first-of-type {
    margin-left: 8px;
  }
}
.CheckboxWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);