.selectInputField {
  > div > div {
    border-radius: 5px !important;
    padding: 4px 16px !important;
  }
}

.errorAlert {
  margin-top: 10px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);