.button {
  color: #666 !important;
  font-size: 16px !important;
  padding: 2px !important;
}

.button_icon {
  font-size: 16px !important;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);