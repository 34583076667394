.arrowIcon {
  transform: scaleX(-1);

  svg {
    width: 35px;
    height: 35px;
  }

  svg path {
    color: rgba(0, 0, 0, 0.25);
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);