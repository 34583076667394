.errorView {
  cursor: pointer;
  width: fit-content;
  border: 1px dashed var(--primary-color);
  border-radius: 5px;
  padding: 5px 20px;
  display: flex;
  > :first-child {
    font-style: italic;
  }
  > :last-child {
    display: flex;
    align-items: center;
    margin-left: 16px;
    color: var(--primary-color);
  }
  margin: 16px 0 24px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);